const $ = require('jquery');
import cookiesJs from 'js-cookie';
import $script from 'scriptjs';
const observable = require('./src/dep/observable.js');
require('flatpickr/dist/themes/light.css');

Window.prototype.$ = $;
global.$ = global.jQuery = $;

global.Cookies = cookiesJs;
Window.prototype.Cookies = cookiesJs;

global.$script = $script;
Window.prototype.$script = $script;

require('./src/api/app');

window.app.setConfig({
    lang: 'fr',
    colors: {
        primary: '#3643BA',
        secondary: '#ff5a5f',
    },
    breakpoints: {
        small: 500,
        medium: 750,
        intermediate: 1000,
        large: 1280,
    },
});

require('./src/dict/fr.js');
require('./src/ui/travel-search-engine.js');
