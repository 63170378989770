import $script from 'scriptjs';
import jQuery from 'jquery';

(function (window, $) {
    var instance;

    function Core() {
        var self = window.observe(this),
            dict = {};

        //! Configuration
        self.config = {
            lang: null,
            scripts: {},
            colors: {},
            breakpoints: {},
            pikaday: {},
        };

        //! Update configuration
        self.setConfig = function (o) {
            self.config = $.extend(self.config, o);
        };

        //! Set dictionary
        self.setDict = function (lang, langDict) {
            dict[lang] = langDict;
        };

        //! Get dictionary
        self.getDict = function (lang) {
            return dict[lang || self.config.lang];
        };

        //! Lazy load scripts
        self.script = function (names, fn) {
            if (!(names instanceof Array)) names = [names];
            var name = names.shift();
            if (name) {
                self.config.scripts[name]
                    ? $script(
                          self.config.scripts[name],
                          self.script.bind(null, names, fn),
                      )
                    : self.script(names, fn);
            } else fn && fn();
        };

        //! Track event
        self.trackEvent = function (category, action) {
            if (window.ga) {
                window.ga('send', 'event', category, action);
            }
        };
    }

    window.app = instance = new Core();

    $(document).ready(function () {
        instance.trigger('dom:ready', null);
    });
})(window, jQuery);
