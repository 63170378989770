(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#travel-search-engine', $container);

        if ($root.length === 0) return;

        var $form = $('#travel-search-engine-form', $root),
            $selection = $('#travel-search-engine-selection'),
            $results = $('#travel-search-engine-results'),
            $resultsCounter = $('> .header > .counter', $results),
            $resultsSortSelect = $('> .header > .sort > select', $results),
            $resultsList = $results.children('.list'),
            $loadmore = $results.children('.loadmore'),
            $loadmoreLink = $('>.link', $loadmore);

        // Init results sort select: transfer 'change' event
        $resultsSortSelect.on('change', function () {
            $form.trigger('submit');
        });

        // Refresh results and filters
        function refreshResultsAndFilters(html, clearResultsList) {
            var $newPage = $(html),
                $newResults = $('#travel-search-engine-results', $newPage),
                $newResultsCounter = $('> .header > .counter', $newResults),
                $newResultsSortSelect = $(
                    '> .header > .sort > select',
                    $newResults,
                ),
                $newResultsList = $newResults.children('.list'),
                $newLoadmore = $newResults.children('.loadmore'),
                $newLoadmoreLink = $('>.link', $newLoadmore);
            app.trigger('dom:ready', $form);
            if (clearResultsList) $resultsList.html('');
            $newResultsList.children('.result').each(function (i, e) {
                $resultsList.append(e);
            });
            app.trigger('dom:ready', $resultsList);
            $resultsCounter.text($newResultsCounter.text());
            $resultsSortSelect.val($newResultsSortSelect.val());
            if ($newLoadmoreLink.attr('href') !== undefined) {
                $loadmoreLink
                    .text($newLoadmoreLink.text())
                    .attr('href', $newLoadmoreLink.attr('href'));
                $loadmore.toggleClass(
                    'visible',
                    $newLoadmore.hasClass('visible'),
                );
            } else {
                $loadmore.remove();
            }
        }

        function fetchMoreResults(method, url) {
            var dict = app.getDict();
            $form.add($selection).add($results).addClass('is-loading');
            $resultsCounter.text(dict.travel.search_engine.loading);
            $loadmoreLink.text(dict.travel.search_engine.loading);

            const options = {
                method: method,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            };

            return fetch(url, options)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text();
                })
                .then((html) => {
                    $form
                        .add($selection)
                        .add($results)
                        .removeClass('is-loading');
                    return html;
                })
                .catch(() => {
                    $form
                        .add($selection)
                        .add($results)
                        .removeClass('is-loading');
                    $resultsCounter.text(dict.travel.search_engine.error);
                    $loadmoreLink.text(dict.travel.search_engine.error);
                });
        }

        // On loadmore click, refresh results and filters without clearing list
        $loadmoreLink.on('click', function (event) {
            event.preventDefault();
            fetchMoreResults('get', $loadmoreLink.attr('href')).then(
                function (html) {
                    refreshResultsAndFilters(html, false);
                },
            );
        });
    });
})(jQuery, window.app);
